<template>
  <div class="index" v-loading="pageLoading">
    <div class="content">
      <crumbs-custom :arr="TabBar" />
      <div class="cont">
        <div class="txt-center font-weight title">家政服务行业类型</div>
        <div class="box">
          <div class="box-child" v-for="(item, index) in dataList" :key="index">
            <img class="thumb" :src="item.pc_logo" alt="">
            <div class="mask">
              <div class="names">{{item.name}}</div>
              <div class="flex-col txt-center child-marsk">
                <div class="flex-cell txt-center font-weight">{{item.name}}</div>
                <div class="flex-cell txt-center bref">{{item.bref}}</div>
                <div class="flex-cell txt-center btns cursor-pointer">
                  <div @click="$router.push({ path: `/serviceLists/`+item.id })">查看企业</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import { setStore } from '@/utils/auth'
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      dataList: [],
      TabBar: []
    };
  },
  mounted() {
    // this.$store.commit("CRUMBS_CUSTOM", [{title: '家政服务类型', router: '/serviceType'}]);
    // setStore('customArr', [{title: '家政服务类型', router: '/serviceType'}])
    this.TabBar = [{router: '/serviceType', title: '家政服务类型'}]
    l_loadData(this)
  },
  methods: {
    
  },
};

// 加载数据
function l_loadData(pg){
  pg.pageLoading = true;
  requestGet("api/company/cates", {})
    .then((res) => {
      pg.dataList = res.data || []
      pg.pageLoading = false;
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
}

</script>
<style lang="scss" scoped>
  .content{
    width: 1200px;
    margin: 0 auto;
    .cont{
      background: #fff;
    }
    .title{
      font-size: 26px;
      height: 100px;
    }
    .box{
      .box-child{
        width: 270px;
        height: 180px;
        display: inline-block;
        vertical-align: top;
        margin: 30px 15px;
        border-radius: 10px;
        position: relative;
        
        .thumb{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
        }
        .mask{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background: linear-gradient(-45deg, rgba(255,255,255,0.1), rgba(219,62,62,0.1));
          border-radius: 10px;
        }
        .child-marsk{
          display: none;
          .font-weight{
            font-size: 24px
          }
        }
        .names{
          color: #333;
          text-align: right;
          margin-right: 30px;
          font-size: 26px;
          line-height: 180px;
        }
      }
      .box-child:hover{
        border-radius: 10px;
        .mask{
          background: rgb(252,127,23)!important;
          border-radius: 10px;
        }
        .child-marsk{
          display: flex;
          width: 100%;
          height: calc(100% - 30px);
          color: #fff;
          padding: 15px 0;
          .bref{
            font-size: 16px
          }
          .btns{
            div{
              border-radius: 5px;
              border: 1px solid #fff;
              padding: 5px 10px;
              font-size: 14px;
            }
          }
        }
        .names{
          display: none;
          font-size: 30px;
        }
      }
    }
  }
</style>
